import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const printer = {
  state: {
    url: process.env.VUE_APP_API_URL,
  },
  mutations: {
    updateUrl(state, data) {
      state.url = data;
    },
  },
  getters: {},
  actions: {},
};

export default printer;

<template>
  <div class="go-dialog" v-if="show">
    <div class="go-dialog-box">
      <i class="close el-icon-close" @click="close"></i>
      <img
        class="warn-icon"
        src="@/assets/images/icon/warning-circle.png"
        alt=""
      />
      <p class="word">{{ text }}</p>
      <footer class="go-dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "",
      show: false,
    };
  },
  methods: {
    open(data) {
      this.text = data;
      this.show = true;
    },
    close() {
      this.show = false;
      this.callback(false);
    },
    confirm() {
      this.callback(true);
      this.show = false;
    },
    callback(data) {
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.go-dialog {
  @include go-wrap();
  z-index: 10000;
}
.go-dialog-box {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  min-height: 260px;
  max-height: 400px;
  background: #ffffff;
  border-radius: 16px;
  padding: 43px;
  overflow: hidden;
  .close {
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    right: 9px;
    top: 9px;
    color: $lightColor;
  }
  .warn-icon {
    display: block;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .word {
    margin-bottom: 40px;
    color: $titleColor;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  .go-dialog-footer {
    display: flex;
    justify-content: space-around;
    .el-button {
      width: 120px;
      height: 36px;
    }
  }
}
</style>

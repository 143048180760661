<!-- 分页封装 -->
<template>
  <div class="pagination">
    <el-pagination
      background
      layout="total, sizes, prev, pager, next, jumper"
      :current-page.sync="pagination.page"
      :page-size="pagination.limit"
      :total="pagination.count"
      @current-change="handlePageChange"
      :hide-on-single-page="hideOnSinglePage"
      @size-change="handleSizeChange"
      :page-sizes="pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    /**
     *   故意利用object的数据同步更改
     */
    pagination: {
      type: Object,
      default: function () {
        return { page: 1, limit: 30, count: 0 };
      },
      required: true,
    },
    hideOnSinglePage: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Array,
      required: false,
      default() {
        return [1, 10, 20, 30, 40, 50, 100];
      },
    },
  },

  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    handlePageChange(page) {
      this.$emit("pageChange", page);
    },
    handleSizeChange(size) {
      this.pagination.limit = size;
      this.$emit("pageChange", this.pagination);
    },
  },
};
</script>
<style scoped>
.pagination {
  margin-top: 10px;
}
</style>

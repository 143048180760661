import request from "./request";
import store from "../store";
import { cloneDeep, keyBy, sortBy } from "lodash-es";
import treeTool from "tree-tool";
import { config as configMockData } from "@/mock/config";
import { routerList } from "@/router/router";
import { fromList } from "@/utils";
// 获取口岸列表
const getPortList = async (params) => {
  return request.get("/declare/parcel/port", {
    params: { ...params },
  });
};

// 获取帐号列表
const getAccountList = async (params) => {
  return request.get("/user/list", { params });
};
// 新增帐号
const addAccount = async (data) => {
  const formData = cloneDeep(data);
  formData.role_id = formData.role_id.join(",");
  return request.post("/user/create", {
    ...formData,
    username: store.state.CMS.userInfo.username,
    password: store.state.CMS.userInfo.password,
  });
};
// 更新帐号
const updateAccount = async (data) => {
  const formData = cloneDeep(data);
  formData.role_id = formData.role_id.join(",");
  return request.put("/user/edit", {
    ...formData,
    username: store.state.CMS.userInfo.username,
    password: store.state.CMS.userInfo.password,
  });
};
// 移除帐号
const removeAccount = async (id) => {
  return request.delete("/user/delete", {
    data: { id },
  });
};
// 获取帐号详情
const getAccountDetail = async (id) => {
  let res = await request.get("/user/detail", { params: { id } });
  res.data.role_id = res.data.role_id.split(",").map(Number);
  return res;
};

// 获取用户详情
const getUser = async (id) => {
  let res = await request.get("/user/detail", { params: { id } });
  const roleIds = res.data.role_id.split(",").map(Number);
  const routerMap = keyBy(routerList, "name");
  let auth_ids = new Set();
  for (let roleId of roleIds) {
    const res1 = await request.get("/role/detail", { params: { id: roleId } });
    let roleData = res1.data;
    roleData.auth_id.split(",").forEach((auth_id) => {
      auth_ids.add(Number(auth_id));
    });
  }
  const tree = await genTreeByAuthId(auth_ids);

  const roleList = treeTool.toList(tree);

  let routers = [];
  roleList.forEach((item) => {
    if (item.show === true) {
      const permission = item.permissions;
      if (!routerMap[item.name]) {
        if (item.is_router !== 0) {
          // 当增加了网页端权限而前端代码中未增加时，会出现该提示，如果需要添加的页面没有出现，请在router文件夹下添加相应的页面
          console.warn(`前端缺少对应router(${item.name})`);
        }
      } else {
        const router = cloneDeep(routerMap[item.name]);
        router.meta = router.meta || {};
        router.meta.permissions = permission;
        router.id = item.id;
        router.pid = item.pid;
        delete router.children;

        routers.push(router);
      }
    }
  });
  // routers = sortBy(routers, function (o) {
  //   return o.meta.sort;
  // });
  return treeTool.fromList(routers);
};

//获取角色列表
const getRoleList = async (params) => {
  return request.get("/role/list", { params });
};

const genTreeByAuthId = async (auth_ids) => {
  const res2 = await getPermissionList();
  const data2 = res2.data.list;
  const roleList = treeTool.toList(data2);

  const data = roleList.map((item) => {
    if (auth_ids.has(item.id)) {
      item.show = true;
    } else {
      item.show = false;
    }
    delete item.children;
    return item;
  });
  const tree = fromList(data);
  return tree;
};

// 获取角色详情
const getRoleDetail = async (id) => {
  const res1 = await request.get("/role/detail", { params: { id } });
  let roleData = res1.data;
  const auth_ids = new Set(roleData.auth_id.split(",").map(Number));
  delete roleData.auth_id;

  const tree = await genTreeByAuthId(auth_ids);

  return {
    data: {
      ...roleData,
      defaultKeys: [...auth_ids],
      auth: tree,
    },
  };
};

// 获取新增角色时的初始化数据
const getInitRoleDetail = async () => {
  const res2 = await getPermissionList();
  const data2 = res2.data.list;
  const roleList = treeTool.toList(data2);

  const data = roleList.map((item) => {
    item.show = false;
    delete item.children;
    return item;
  });
  const tree = fromList(data);

  return {
    data: {
      defaultKeys: [],
      auth: tree,
    },
  };
};

// 新增角色
const addRole = async (formData, checkedKeys) => {
  let auth_id = [...checkedKeys];
  const treeList = treeTool.toList(formData.auth);
  treeList.forEach((item) => {
    if (item.permissions && item.permissions.length !== 0) {
      item.permissions.forEach((item2) => {
        if (item2.show) auth_id.push(item2.id);
      });
    }
  });

  const data = {
    name: formData.name,
    explain: formData.explain,
    auth_id: auth_id.join(","),
  };

  return request.post("/role/create", data);
};
// 更新角色
const updateRole = async (formData, checkedKeys) => {
  let auth_id = [...checkedKeys];
  const treeList = treeTool.toList(formData.auth);
  treeList.forEach((item) => {
    if (item.permissions && item.permissions.length !== 0) {
      item.permissions.forEach((item2) => {
        if (item2.show) auth_id.push(item2.id);
      });
    }
  });

  const data = {
    id: formData.id,
    name: formData.name,
    explain: formData.explain,
    auth_id: auth_id.join(","),
  };
  console.log("updateRole", formData, data);

  return request.put("/role/edit", data);
};
// 移除角色
const removeRole = async (id) => {
  return request.delete("/role/delete", {
    data: { id },
  });
};

// 权限列表
const getPermissionList = async () => {
  return request.get("/auth/list");
};
// 权限添加
const addPermission = (data) => {
  return request.post("/auth/create", data);
};
// 权限删除
const removePermission = (id) => {
  return request.delete("/auth/delete", {
    data: { id },
  });
};
// 权限修改
const updatePermission = (data) => {
  return request.put("/auth/edit", data);
};

const config = {
  getPortList,
  getAccountList,
  updateAccount,
  removeAccount,
  getRoleList,
  updateRole,
  removeRole,
  addAccount,
  addRole,
  getRoleDetail,
  getAccountDetail,
  getPermissionList,
  addPermission,
  getUser,
  removePermission,
  updatePermission,
  getInitRoleDetail,
};

export default config;

import * as dayjs from "dayjs";
import { Message } from "element-ui";
import router from "@/router";

export const arrayToObject = (arr, key) => {
  let obj = {};
  arr.forEach((element) => {
    obj[element[key]] = element;
  });
  return obj;
};

export const getNow = () => {
  const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
  return now;
};

export const date2String = (date) => {
  return dayjs(date).format("YYYYMMDDHHmmss");
};

export const sumFromArr = (array, field) => {
  let sum = 0;
  array.forEach((item, _) => {
    if (item[field] !== undefined) {
      sum += Number(item[field]);
    } else {
      sum = "";
      return;
    }
  });
  return sum;
};

export const declareValid = (data, formMap, goodsMap, exclude = []) => {
  Object.entries(data).forEach(([key, value], _) => {
    if (exclude.includes(key)) return;
    if (value === undefined || value === "") {
      Message.error(`${formMap[key] ? formMap[key].title : key} 不能为空`);
      throw Error(`${key} 不能为空`);
    }
    if (key === "details") {
      value.forEach((item, _index) => {
        Object.entries(item).forEach(([key2, value2], _) => {
          if (exclude.includes(key2)) return;
          if (value2 === undefined || value2 === "") {
            Message.error(
              `货物详情第${_index + 1}项的 ${
                goodsMap[key2] ? goodsMap[key2].title : key2
              } 不能为空`
            );
            throw Error(`货物详情第${_index + 1}项的 ${key2} 不能为空`);
          }
        });
      });
    }
  });
};

export const valid = {
  isNumber: (rule, value, callback) => {
    if (isNaN(value)) {
      callback(rule.message);
    }
  },
};

const DEFAULT_CONFIG = {
  id: "id",
  children: "children",
  pid: "pid",
};

const getConfig = (config) => Object.assign({}, DEFAULT_CONFIG, config);

export const fromList = (list, config = {}) => {
  config = getConfig(config);
  const nodeMap = new Map(),
    result = [],
    { id, children, pid } = config;
  for (const node of list) {
    node[children] = node[children] || [];
    node["permissions"] = node["permissions"] || [];
    nodeMap.set(node[id], node);
  }
  for (const node of list) {
    const parent = nodeMap.get(node[pid]);
    if (parent) {
      if (node.is_router === 0) {
        parent["permissions"].push(node);
      } else {
        parent[children].push(node);
      }
    } else {
      result.push(node);
    }
  }
  return result;
};

// 权限检查
export const checkPermission = (permission) => {
  const permissionList = router.currentRoute.meta.permissions || [];

  return permissionList.some((item) => {
    return item.name === permission && item.show === true;
  });
};

// 拆分字符串
export const chunk = (string, sep, limit) => {
  const splitStringArr = string.split(sep);
  const length = splitStringArr.length;
  if (limit >= length) {
    return splitStringArr.concat(Array(limit - length).fill(""));
  } else {
    let limitStringArr = splitStringArr.slice(0, limit - 1);
    let resrString = splitStringArr.slice(limit - 1).join(sep);
    return limitStringArr.concat(resrString);
  }
};

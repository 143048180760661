export default [
  {
    path: "/options",
    name: "options",
    meta: {
      label: "设置",
      sort: 5,
    },
    component: () => import("@/views/options/index"),
    children: [
      {
        path: "/options/custom",
        name: "portSetting",
        meta: {
          label: "口岸管理",
        },
        component: () => import(`@/views/options/customManager/index`),
      },
      {
        path: "/options/permission",
        name: "permissionSetting",
        meta: {
          label: "权限设置",
        },
        component: () => import(`@/views/options/permissionManager`),
        children: [
          {
            path: "/options/permission/account",
            name: "accountSetting",
            meta: {
              label: "账号",
            },
            component: () =>
              import(`@/views/options/permissionManager/account`),
          },
          {
            path: "/options/permission/role",
            name: "roleSetting",
            meta: {
              label: "角色",
            },
            component: () => import(`@/views/options/permissionManager/role`),
          },
          {
            path: "/options/permission/permission",
            name: "permissionSettings",
            meta: {
              label: "权限",
            },
            component: () =>
              import(`@/views/options/permissionManager/permission`),
          },
        ],
      },
    ],
  },
];

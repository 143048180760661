import Vue from "vue";
import Vuex from "vuex";
import declare from "./declare";
import printer from "./printer";
import { keyBy, sortBy } from "lodash-es";
import treeTool from "tree-tool";

Vue.use(Vuex);

const CMS = {
  state: {
    url: process.env.VUE_APP_API_URL,
    userInfo: JSON.parse(window.localStorage.getItem("userInfo") || "{}"),
    routers: [],
    routerOrigin: [],
    activeMainMenu: "",
    activeSubMenu: "",
    isProduction: process.env.VUE_APP_ENV === "production",
  },
  mutations: {
    login(state, data) {
      window.localStorage.setItem("userInfo", JSON.stringify(data));
      state.userInfo = data;
    },
    logout(state) {
      window.localStorage.removeItem("userInfo");
      state.userInfo = null;
    },
    updateRouters(state, data) {
      state.routers = data;
    },
    updateOriginRouter(state, data) {
      state.routerOrigin = data;
    },
    updateActiveMainMenu(state, name) {
      state.activeMainMenu = name;
    },
    updateActiveSubMenu(state, data) {
      state.activeSubMenu = data;
    },
  },
  getters: {
    backendUrl(state) {
      return `//${
        state.isProduction ? "admin.rennigou.jp" : "admin.0308.me:8016"
      }`;
    },
    headerRouters(state) {
      return sortBy(
        state.routerOrigin.filter((router) => {
          return router.pid === 0;
        }),
        function (o) {
          return o.meta.sort;
        }
      );
    },
    subMenu(state, getters) {
      const obj = keyBy(getters.headerRouters, "name");
      const subMenu = obj[state.activeMainMenu];
      if (!subMenu) {
        return [];
      }
      return subMenu.children;
    },
    breadcrumbs(state, getters) {
      const activeName = state.activeSubMenu;
      const data = treeTool.findPath(getters.headerRouters, (node) => {
        return node.name === activeName;
      });
      return data.map((item) => item.meta.label);
    },
  },
  actions: {},
};

export default new Vuex.Store({
  state: {
    breadcrumb_show: true,
  },
  modules: {
    CMS,
    declare,
    printer,
  },
});

import router from "@/router";
import { checkPermission } from "@/utils";
// function checkPermission(permission) {
//   const permissionList = router.currentRoute.meta.permissions || [];

//   return permissionList.some((item) => {
//     return item.name === permission && item.show === true;
//   });
// }

export default {
  inserted(el, binding, vnode) {
    let permission = binding.value; // 获取到 v-permission的值
    if (permission) {
      let hasPermission = false;
      if (Array.isArray(permission)) {
        hasPermission = permission.some((ele) => checkPermission(ele));
      } else {
        hasPermission = checkPermission(permission); // 检测是否有权限
      }

      if (!hasPermission) {
        // 没有权限 移除Dom元素
        el.classList.add("display-none");
      }
    }
  },
};

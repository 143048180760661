// 打单
export default [
  {
    path: "/expressBill",
    name: "expressBill",
    meta: {
      label: "打单",
      sort: 4,
    },
    component: () => import("@/views/expressBill/index"),
    children: [
      {
        path: "/expressBill/import",
        name: "expressBillImport",
        meta: {
          label: "数据导入",
        },
        component: () => import(`@/views/expressBill/dataImport/index`),
      },
      {
        path: "/expressBill/setting",
        name: "expressBillSetting",
        meta: {
          label: "设置",
        },
        component: () => import(`@/views/expressBill/setting/index`),

        children: [
          {
            path: "/expressBill/setting/sender",
            name: "expressBillSenderSettting",
            meta: {
              label: "寄件人管理",
            },
            component: () =>
              import(`@/views/expressBill/setting/senderSetting/index`),
          },
          {
            path: "/expressBill/setting/sheet",
            name: "expressBillSheetSettting",
            meta: {
              label: "面单管理",
            },
            component: () =>
              import(`@/views/expressBill/setting/sheetSetting/index`),
          },
          {
            path: "/expressBill/setting/sheetTemplate",
            name: "expressBillSheetTemplateSettting",
            meta: {
              label: "面单库",
            },
            component: () =>
              import(`@/views/expressBill/setting/sheetTemplate/index`),
          },
        ],
      },
      {
        path: "/expressBill/sheetPrint",
        name: "expressBillSheetPrint",
        meta: {
          label: "面单打印",
        },
        component: () => import(`@/views/expressBill/sheetPrint/index`),
      },
      {
        path: "/expressBill/design",
        name: "expressBillSheetDesign",
        meta: {
          label: "面单模板设计",
        },
        component: () => import(`@/views/expressBill/design/index`),
      },
    ],
  },
];

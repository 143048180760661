<template>
  <!-- Note that row-key is necessary to get a correct row order. -->
  <el-table
    ref="dragTable"
    v-loading="listLoading"
    :data="list"
    row-key="id"
    border
    fit
    highlight-current-row
    style="width: 100%"
    @sort-change="sortChange"
  >
    <el-table-column
      header-align="center"
      show-overflow-tooltip
      v-for="(item, index) in header || []"
      :key="index"
      :prop="item.value"
      :align="item.align || 'center'"
      :label="item.name"
      :width="item.width || ''"
      :fixed="item.fixed || false"
      :sortable="item.sort"
      :resizable="false"
    >
      <template slot-scope="{ row }">
        <slot v-if="item.slot || false" :name="item.slot" :row="row"></slot>
        <span v-else-if="item.value && !item.slot">{{
          row[item.value] || "-"
        }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
// import { fetchList } from '@/api/article'
import Sortable from "sortablejs";
// import Sortable from "@/static/sortablejs/Sortable.js";

export default {
  name: "DragTable",
  props: {
    listQuery: { type: Object, default: () => {} },
    header: { type: Array, default: () => [] },
    sort: { type: Boolean, default: false },
  },
  data() {
    return {
      list: [],
      total: null,
      listLoading: true,
      sortable: null,
      oldList: [],
      newList: [],
    };
  },
  watch: {
    listQuery: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    if (this.listQuery.data) {
      this.getList();
    }
  },
  methods: {
    async getList() {
      try {
        // this.listLoading = true;
        const { data, total } = this.listQuery;
        this.list = data || [];
        this.total = total;
        this.listLoading = false;
        this.oldList = this.list.map((v) => v.id);
        this.newList = this.oldList.slice();
        if (this.sort) {
          this.$nextTick(() => {
            this.setSort();
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost", // Class name for the drop placeholder,
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          const targetRow = this.list.splice(evt.oldIndex, 1)[0];
          this.list.splice(evt.newIndex, 0, targetRow);
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
          this.newList.splice(evt.newIndex, 0, tempIndex);
          this.list.forEach((item, index) => {
            item.sort = index + 1;
          });
          this.$emit("change", this.list);
        },
      });
    },
    sortChange(val) {
      this.$emit("sort-change", val);
    },
  },
};
</script>

<style>
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #42b983 !important;
}
</style>

<style scoped>
.el-table {
  /* height: 100%; */
  min-width: 1080px;
}
.icon-star {
  margin-right: 2px;
}
.drag-handler {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.show-d {
  margin-top: 15px;
}
</style>

import Home from "../views/Home.vue";
import declare from "./declare";
import options from "./options";
import clear from "./clear";
import ship from "./ship";
import home from "./home";
import expressBill from "./expressBill";
import login from "../views/Login";
import treeTool from "tree-tool";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [],
    // redirect: "/home",
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
];
// export default routes;
export const routerList = [
  ...treeTool.toList(declare),
  ...treeTool.toList(options),
  ...treeTool.toList(clear),
  ...treeTool.toList(ship),
  ...treeTool.toList(home),
  ...treeTool.toList(expressBill),
];

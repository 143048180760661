import { arrayToObject } from "@/utils";

const declare = {
  state: {
    parcelDetail: {},
    portList: [],
    goods: [],
  },
  mutations: {
    updateParcel(state, data) {
      state.parcelDetail = data;
    },
    updatePortList(state, data) {
      state.portList = data;
    },
    updateGoods(state, data) {
      state.goods = data;
    },
  },
  getters: {
    portOptions(state) {
      let arr = [
        {
          label: "全部",
          value: 0,
        },
      ];
      state.portList.forEach((element) => {
        arr.push({
          label: element.port_name,
          value: element.id,
        });
      });

      return arr;
    },
    portOptionsMap(_, getters) {
      return arrayToObject(getters.portOptions, "value");
    },
  },
  actions: {},
};

export default declare;

export default [
  {
    path: "/ship",
    name: "ship",
    meta: {
      label: "发货",
      sort: 2,
    },
    component: () => import("@/views/ship/index"),
    children: [
      {
        path: "/ship/stockOut",
        name: "stockOut",
        meta: {
          label: "出库",
        },
        component: () => import(`@/views/ship/ship`),
      },
      {
        path: "/ship/packUp",
        name: "packUp",
        meta: {
          label: "打包",
        },
        component: () => import(`@/views/ship/ship`),
        children: [
          {
            path: "/ship/packUp/EIP",
            name: "EIP",
            meta: {
              label: "打单",
            },
            component: () => import(`@/views/ship/ship`),
          },
        ],
      },
      {
        path: "/ship/daban",
        name: "daban",
        meta: {
          label: "打板",
        },
        component: () => import(`@/views/ship/daban/index`),
        children: [
          {
            path: "/ship/daban/dabanWait",
            name: "dabanWait",
            meta: {
              label: "标准物流待打板",
              sort: 1,
            },
            component: () => import(`@/views/ship/daban/dabanWait/index`),
          },
          {
            path: "/ship/daban/kaban",
            name: "kaban",
            meta: {
              label: "卡板管理",
              sort: 3,
            },
            component: () => import(`@/views/ship/daban/kaban/index`),
          },
          {
            path: "/ship/daban/noStandard",
            name: "noStandard",
            meta: {
              label: "非标打板待打板",
              sort: 2,
            },
            component: () => import(`@/views/ship/daban/noStandard/index`),
          },
          {
            path: "/ship/daban/expressNoMatch",
            name: "expressNoMatch",
            meta: {
              label: "临时物流号匹配",
              sort: 2,
            },
            component: () => import(`@/views/ship/daban/expressNoMatch/index`),
          },
        ],
      },
    ],
  },
];

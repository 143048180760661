<template>
  <header class="header">
    <aside>
      <el-menu
        :default-active="headerIndex"
        mode="horizontal"
        class="flex menu"
        background-color="#fff"
        text-color="#666"
        active-text-color="#6bd3d3"
        @select="handleSelect"
      >
        <el-menu-item
          :index="item.name"
          v-for="(item, index) in headerRoutes"
          :key="index"
          >{{ item.meta.label }}</el-menu-item
        >
      </el-menu>
    </aside>
    <aside><Avatar></Avatar></aside>
  </header>
</template>
<script>
import Avatar from "@/components/Avatar";
import { keyBy } from "lodash-es";

export default {
  components: {
    Avatar,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    const routersMap = keyBy(this.routers, "name");
    const router = routersMap[this.$route.name];
    const name = this.getActiveMenu(router);
    console.log("router", name, router, this.$route.name, routersMap);

    this.$store.commit("updateActiveMainMenu", name);
  },
  computed: {
    routers() {
      return this.$store.state.CMS.routers;
    },
    headerRoutes() {
      return this.$store.getters.headerRouters;
    },
    headerIndex() {
      return this.$store.state.CMS.activeMainMenu;
    },
  },
  watch: {},
  methods: {
    getActiveMenu(router) {
      if (router.parent.name === "Home") {
        return router.name;
      } else {
        return this.getActiveMenu(router.parent);
      }
    },
    handleSelect(key) {
      this.$store.commit("updateActiveMainMenu", key);
      this.$router.push({ name: key });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #fff;
  justify-content: space-between;
  color: #666;
  width: 100%;
  overflow: hidden;
  border-bottom: 2px solid #eee;
  .el-menu-item {
    font-size: 18px;
    width: 160px;
    border: none;
    &.is-active {
      border: none;
      position: relative;
      &::after {
        content: " ";
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 8px;
        width: 52px;
        height: 6px;
        border-radius: 4px;
        background-color: $mainColor;
      }
    }
  }
  aside {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    &:nth-of-type(2) {
      width: 150px;
    }
  }
}
.menu {
  flex: 1;
}
</style>

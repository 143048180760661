import axios from "axios";
import router from "../router";
import { Message } from "element-ui";
import store from "../store";
import qs from "qs";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

instance.interceptors.request.use((config) => {
  const userInfo = store.state.CMS.userInfo;
  if (userInfo && userInfo.token) {
    if (config.method === "get") {
      if (config.params) {
        config.params.token = userInfo.token;
      } else {
        config.params = { token: userInfo.token };
      }
    } else if (["post", "put", "delete"].includes(config.method)) {
      if (
        config.headers["Content-Type"] === "application/x-www-form-urlencoded"
      ) {
        if (config.data) {
          config.data = qs.stringify({ ...config.data, token: userInfo.token });
        } else {
          config.data = qs.stringify({ token: userInfo.token });
        }
      } else if (config.headers["Content-Type"] === "multipart/form-data") {
        config.data.append("token", userInfo.token);
      }
    }

    return config;
  } else {
    router.replace({
      path: "/login",
      query: { redirect: router.currentRoute.fullPath },
    });
  }
  // const token = window.localStorage.getItem("access_token");
  // if (token && !config.headers.Authorization) {
  //   config.headers.Authorization = `Bearer ${token}`;
  // }
  return config;
}),
  (error) => {
    return Promise.error(error);
  };

instance.interceptors.response.use(
  (response) => {
    // 当值为blob时，直接返回数据
    if (response.status === 200 && response.data instanceof Blob) {
      const fileName =
        response.headers["content-disposition"].split("filename=")[1];
      const file = new File([response.data], decodeURI(escape(fileName)));
      return Promise.resolve(file);
    }
    if (
      response.status === 200 &&
      [10000, 1000, 0].includes(response.data.code)
    ) {
      return Promise.resolve(response.data);
    } else if (response.status === 200) {
      const requestUrl = response.config.url;
      const excludeUrls = ["/declare/parcel/push"];
      if (excludeUrls.includes(requestUrl)) {
        return Promise.resolve(response.data);
      }

      Message.error(response.data.msg);
      return Promise.reject(response.data.msg);
    } else {
      return Promise.reject(response.data || response);
    }
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          store.commit("logout");
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath },
          });
          break;
        case 403:
          Message.error("您没有权限");
          break;
        case 404:
          Message.error(`没有找到 ${error.response.config.url} 接口`);
          break;
        case 500:
          Message.error("服务端错误");
          break;
      }
    }
    return Promise.reject(error); // 返回接口返回的错误信息
  }
);

export default instance;

// 申报相关路径
export default [
  {
    path: "/declare",
    name: "declare",
    meta: {
      label: "申报",
      sort: 1,
    },
    component: () => import("@/views/declare/index"),
    children: [
      {
        path: "/declare/packageList",
        name: "declareList",
        meta: {
          label: "申报包裹列表",
        },
        component: () => import(`@/views/declare/packageListManager/index`),
      },
      {
        path: "/declare/declare",
        name: "declareMain",
        meta: {
          label: "做单申报",
        },
        component: () => import(`@/views/declare/declare/index`),
        children: [
          {
            path: "/declare/declare/tianjingHaiTaoTong/index",
            name: "declareTianjingHaiTaoTong",
            meta: {
              label: "天津口岸",
            },
            component: () =>
              import(`@/views/declare/declare/tianjingHaiTaoTong/index`),
            children: [
              {
                path: "/declare/declare/tianjingHaiTaoTong/init",
                name: "declareTianjingHaiTaoTongInit",
                meta: {
                  label: "待做单",
                },
                component: () =>
                  import(
                    `@/views/declare/declare/tianjingHaiTaoTong/init/index`
                  ),
              },
              {
                path: "/declare/declare/tianjingHaiTaoTong/wait",
                name: "declareTianjingHaiTaoTongWait",
                meta: {
                  label: "待申报",
                },
                component: () =>
                  import(
                    `@/views/declare/declare/tianjingHaiTaoTong/wait/index`
                  ),
              },
              {
                path: "/declare/declare/tianjingHaiTaoTong/declare",
                name: "declareTianjingHaiTaoTongDeclare",
                meta: {
                  label: "已申报",
                },
                component: () =>
                  import(
                    `@/views/declare/declare/tianjingHaiTaoTong/declare/index`
                  ),
              },
              {
                path: "/declare/declare/tianjingHaiTaoTong/failure",
                name: "declareTianjingHaiTaoTongFailure",
                meta: {
                  label: "申报失败",
                },
                component: () =>
                  import(
                    `@/views/declare/declare/tianjingHaiTaoTong/failure/index`
                  ),
              },
              {
                path: "/declare/declare/tianjingHaiTaoTong/lockList",
                name: "declareTianjingHaiTaoTongLockList",
                meta: {
                  label: "锁单列表",
                  hide: true,
                },
                component: () =>
                  import(
                    `@/views/declare/declare/tianjingHaiTaoTong/lockList/index`
                  ),
              },
              {
                path: "/declare/declare/tianjingHaiTaoTong/taxInfo",
                name: "declareTianjingHaiTaoTongTaxInfo",
                meta: {
                  label: "当前批次出税情况",
                  hide: true,
                },
                component: () =>
                  import(
                    `@/views/declare/declare/tianjingHaiTaoTong/taxInfo/index`
                  ),
              },
            ],
          },
          {
            path: "/declare/declare/tianjingHaiTaoTong/detail",
            name: "declareTianjingHaiTaoTongDetail",
            meta: {
              label: "天津口岸-海淘通-做单页面",
              hide: true,
            },
            component: () =>
              import(`@/views/declare/declare/tianjingHaiTaoTong/detail`),
          },

          {
            path: "/declare/declare/shanghaiYuanZhou",
            name: "declareShanghaiYuanZhou",
            meta: {
              label: "上海口岸-园洲",
            },
            component: () =>
              import(
                `@/views/declare/declare/shanghaiYuanZhou/shanghaiYuanZhou`
              ),
          },
          {
            path: "/declare/declare/shanghaiYuanZhou/detail",
            name: "declareShanghaiYuanZhouDetail",
            meta: {
              label: "上海口岸-园洲-做单页面",
              hide: true,
            },
            component: () =>
              import(`@/views/declare/declare/shanghaiYuanZhou/detail`),
          },

          {
            path: "/declare/declare/shanghai",
            name: "declareShanghai",
            meta: {
              label: "上海口岸",
            },
            component: () => import(`@/views/declare/declare/shanghai`),
          },
          {
            path: "/declare/declare/ningbo",
            name: "declareNingbo",
            meta: {
              label: "宁波口岸",
            },
            component: () => import(`@/views/declare/declare/ningbo`),
          },
          {
            path: "/declare/declare/jiangmen",
            name: "declareJiangmen",
            meta: {
              label: "江门口岸",
            },
            component: () => import(`@/views/declare/declare/jiangmen`),
          },
          {
            path: "/declare/declare/xianggang",
            name: "declareHongkong",
            meta: {
              label: "香港口岸",
            },
            component: () => import(`@/views/declare/declare/xianggang`),
          },
          {
            path: "/declare/declare/zhanjiang",
            name: "declareZhanjiang",
            meta: {
              label: "湛江口岸",
            },
            component: () => import(`@/views/declare/declare/zhanjiang`),
          },
          {
            path: "/declare/declare/ems/index",
            name: "declareEms",
            meta: {
              label: "邮政系物流",
            },
            component: () => import(`@/views/declare/declare/ems/index`),
            children: [
              {
                path: "/declare/declare/ems/init",
                name: "declareEmsInit",
                meta: {
                  label: "待确认信息",
                },
                component: () => import(`@/views/declare/declare/ems/init`),
              },
              {
                path: "/declare/declare/ems/wait",
                name: "declareEmsWait",
                meta: {
                  label: "待导出",
                },
                component: () => import(`@/views/declare/declare/ems/wait`),
              },
              {
                path: "/declare/declare/ems/complete",
                name: "declareEmsComplete",
                meta: {
                  label: "已导出",
                },
                component: () => import(`@/views/declare/declare/ems/complete`),
              },
              {
                path: "/declare/declare/ems/detail",
                name: "declareEmsDetail",
                meta: {
                  label: "详情",
                  hide: true,
                },
                component: () => import(`@/views/declare/declare/ems/detail`),
              },
            ],
          },
          {
            path: "/declare/declare/proxyDeclare/index",
            name: "proxyDeclare",
            meta: {
              label: "代理清关",
            },
            component: () =>
              import(`@/views/declare/declare/proxyDeclare/index`),
            children: [
              {
                path: "/declare/declare/proxyDeclare/init",
                name: "proxyDeclareInit",
                meta: {
                  label: "待做单",
                },
                component: () =>
                  import(`@/views/declare/declare/proxyDeclare/init/index`),
              },
              {
                path: "/declare/declare/proxyDeclare/wait",
                name: "proxyDeclareWait",
                meta: {
                  label: "待申报",
                },
                component: () =>
                  import(`@/views/declare/declare/proxyDeclare/wait/index`),
              },
              {
                path: "/declare/declare/proxyDeclare/failure",
                name: "proxyDeclareFailure",
                meta: {
                  label: "申报失败",
                },
                component: () =>
                  import(`@/views/declare/declare/proxyDeclare/failure/index`),
              },
              {
                path: "/declare/declare/proxyDeclare/exportData",
                name: "proxyDeclareExportData",
                meta: {
                  label: "已导出数据",
                },
                component: () =>
                  import(
                    `@/views/declare/declare/proxyDeclare/exportData/index`
                  ),
              },
              {
                path: "/declare/declare/proxyDeclare/lockList",
                name: "proxyDeclareLockList",
                meta: {
                  label: "锁单列表",
                  hide: true,
                },
                component: () =>
                  import(`@/views/declare/declare/proxyDeclare/lockList/index`),
              },
              {
                path: "/declare/declare/proxyDeclare/taxInfo",
                name: "proxyDeclareTaxInfo",
                meta: {
                  label: "批次数据出税情况",
                  hide: true,
                },
                component: () =>
                  import(`@/views/declare/declare/proxyDeclare/taxInfo/index`),
              },
              {
                path: "/declare/declare/proxyDeclare/detail",
                name: "proxyDeclareDetail",
                meta: {
                  label: "详情",
                  hide: true,
                },
                component: () =>
                  import(`@/views/declare/declare/proxyDeclare/detail/index`),
              },
            ],
          },
        ],
      },
      {
        path: "/declare/dataDict",
        name: "dataDict",
        meta: {
          label: "数据字典",
        },
        component: () => import(`@/views/declare/dataDict/index`),
        children: [
          {
            path: "/declare/dataDict/constant",
            name: "dataDictConstant",
            meta: {
              label: "常量配置",
            },
            component: () => import(`@/views/declare/dataDict/constant/index`),
          },
          {
            path: "/declare/dataDict/constant/detail",
            name: "dataDictConstantDetail",
            meta: {
              label: "常量配置详情页",
              hide: true,
            },
            component: () => import(`@/views/declare/dataDict/constant/detail`),
          },
          {
            path: "/declare/dataDict/businessCode",
            name: "businessCode",
            meta: {
              label: "业务代码",
            },
            component: () =>
              import(`@/views/declare/dataDict/businessCode/index`),
          },
          {
            path: "/declare/dataDict/businessCode/detail",
            name: "businessCodeDetail",
            meta: {
              label: "业务代码详情页",
              hide: true,
            },
            component: () =>
              import(`@/views/declare/dataDict/businessCode/detail`),
          },
        ],
      },
    ],
  },
];

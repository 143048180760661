import request from "./request";

/***************清关*****************/

// 获取清关结果导入模板
const getclearResultTemplate = () => {
  return request.get("/plate/clearance/export", {
    responseType: "blob",
  });
};
// 导入清关数据
const importClaearPackage = (data) => {
  return request.post("/plate/clearance/create", data);
};

// 查询清关包裹列表
const getClearPackageList = (params) => {
  return request.get("/plate/parcel/list", {
    params: {
      ...{ is_plate: 0, is_standard: 0 },
      ...params,
    },
  });
};

// 清关包裹编辑
const editClearPackage = (data) => {
  return request.post("", data);
};

// 清关包裹保存
const saveClearPackage = (data) => {
  return request.put("/plate/clearance/save", data);
};

// 包裹状态转化清关成功
const updateClearPackageStatusToSuccess = (data) => {
  return request.put("/plate/clearance/edit", data);
};

// 包裹状态转化清关失败
const updateClearPackageStatusToFailure = (id) => {
  return request.put("/plate/clearance/error", { id });
};

// 获取包裹详情
const getClearPackageDetail = (id) => {
  return request.get("/plate/clearance/detail", { params: { id } });
};

const clear = {
  getclearResultTemplate,
  importClaearPackage,
  getClearPackageList,
  editClearPackage,
  saveClearPackage,
  getClearPackageDetail,
  updateClearPackageStatusToSuccess,
  updateClearPackageStatusToFailure,
};

export default clear;

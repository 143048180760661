export default [
  {
    path: "/home",
    name: "home",
    meta: {
      label: "首页",
      sort: 0,
    },
    component: () => import("@/views/index/index"),
    children: [
      {
        path: "/home/test",
        name: "homeTest",
        meta: {
          label: "测试",
        },
        component: () => import(`@/views/index/test`),
      },
    ],
  },
];

<template>
  <div class="relative sider" :style="{ width: isCollapse ? '60px' : '' }">
    <div class="header">
      <img class="logo center" src="../assets/images/logo@2x.png" />
    </div>
    <div @click="isCollapse = !isCollapse" class="absolute collapse">
      <i class="el-icon-arrow-left pointer" v-if="isCollapse"></i>
      <i class="el-icon-arrow-right pointer" v-if="!isCollapse"></i>
    </div>
    <el-menu
      :unique-opened="true"
      :default-active="activeIndex"
      text-color="#333"
      active-text-color="#6bd3d3"
      @select="handleSelect"
      :collapse="isCollapse"
    >
      <div v-for="item in list" :key="item.name">
        <el-submenu
          v-if="item.children.length !== 0 && !item.meta.hide"
          :index="item.name"
        >
          <template slot="title"
            ><span class="span">{{ item.meta.label }}</span></template
          >
          <template v-for="data in item.children">
            <el-submenu
              v-if="data.children.length !== 0 && !data.meta.hide"
              :index="data.name"
              :key="data.name"
            >
              <template slot="title"
                ><span class="span">{{ data.meta.label }}</span></template
              >
              <template v-for="item2 in data.children">
                <el-menu-item
                  v-if="!item2.meta.hide"
                  :index="item2.name"
                  :key="item2.name"
                  >{{ item2.meta.label }}</el-menu-item
                >
              </template>
            </el-submenu>

            <el-menu-item
              v-else-if="!item.children.length !== 0 && !data.meta.hide"
              :index="data.name"
              :key="data.name"
              >{{ data.meta.label }}</el-menu-item
            >
          </template>
        </el-submenu>
        <el-menu-item
          v-else-if="!item.children.length !== 0 && !item.meta.hide"
          :index="item.name"
          >{{ item.meta.label }}</el-menu-item
        >
      </div>
    </el-menu>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      activeIndex: this.$route.name,
      isCollapse: false,
    };
  },
  created() {},
  mounted() {},
  computed: {
    list() {
      return this.$store.getters.subMenu;
    },
  },
  watch: {
    "$route.name": {
      handler(newName) {
        this.$store.commit("updateActiveSubMenu", newName);
      },
      immediate: true,
    },
  },
  methods: {
    handleSelect(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin menuLine() {
  min-width: 0;
  border-radius: 0px 32px 32px 0px;
  &.is-active {
    background-color: rgba(231, 232, 246, 0.6) !important;
    position: relative;
    &::after {
      content: " ";
      display: block;
      width: 6px;
      height: 18px;
      background: rgba(107, 211, 211, 0.5);
      border-radius: 3px;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.collapse {
  right: 4px;
  top: 64px;
  z-index: 10;
}
.header {
  background-color: #fff;
  position: relative;
  // padding: 8px 24px;
  // border-bottom: 2px solid #eee;
  height: 60px;
  .logo {
    width: 80%;
  }
}
.el-menu {
  background-color: $homeSiderColor;
  text-align: left;
  padding-right: 15px;
}
::v-deep.el-submenu {
  .el-menu {
    background: none;
  }
  .el-menu-item {
    padding-right: 0px !important;
  }
  &.is-active {
    &.is-opened > div > .span {
      color: $mainColor;
    }
    .el-menu-item {
      @include menuLine();
      // padding-right: 0px !important;
      &.is-active {
        &::after {
          left: 20px;
        }
      }
    }
  }
}
.icon {
  width: 14px;
  height: 14px;
  object-fit: contain;
  margin-right: 5px;
  display: inline-block;
}
::v-deep.el-submenu__title {
  display: flex !important;
  align-items: center !important;
}
.el-menu-item {
  display: flex !important;
  align-items: center !important;
}
</style>

import request from "./request";

// 图片上传
const imageUpload = (formData) => {
  return request.post("/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const common = {
  imageUpload,
};

export default common;

<!-- 头像栏 -->
<template>
  <div class="">
    <el-dropdown @command="handleCommand" placement="bottom">
      <span class="el-dropdown-link">
        <el-avatar class="pointer"> user </el-avatar>
        <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="logout">登出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { auth } from "../api";

export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    async handleCommand(command) {
      if (command === "logout") {
        const res = await auth.logout();
        if (res.code === 1000) {
          this.$store.commit("logout");
          this.$router.replace("/login");
          window.location.reload();
        } else {
          console.warn(res.msg, "登出失败");
        }
      }
    },
  },
};
</script>
<style scoped></style>

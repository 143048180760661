<template>
  <div id="godialog" class="go-dialog" v-if="show">
    <div class="go-dialog-box" :style="{ width }">
      <i class="close el-icon-close" @click="close"></i>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: "show",
    event: "toggle",
  },
  props: {
    show: { type: Boolean, default: false },
    width: { type: String, default: "380px" },
    height: { type: String, default: "" },
  },
  methods: {
    close() {
      this.$emit("toggle", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.go-dialog {
  @include go-wrap();
}
.go-dialog-box {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 16px;
  padding: 26px;
  overflow: auto;
  max-height: 80vh;
  min-height: 200px;
  .close {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 9px;
    top: 5px;
    color: $lightColor;
  }
}
</style>

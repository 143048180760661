import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import axios from "./api/request";
import store from "./store";
import Element from "element-ui";
import dayjs from "dayjs";
import dialog from "@/components/common/GoDialog";
import godialog from "@/components/commonUI/Dialog";
import Breadcrumb from "@/components/commonUI/Breadcrumb";
import BPagination from "@/components/commonUI/BPagination";
import DragTable from "@/components/common/DragTable.vue";
import { checkPermission } from "@/utils";

import permission from "./directives/permissions";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Element);
Vue.use(VueCompositionApi);

const dialogBox = Vue.extend(dialog);
const dialogBoxInstance = new dialogBox().$mount();
document.body.appendChild(dialogBoxInstance.$el);
const dialogBoxCtl = (text) => {
  dialogBoxInstance.open(text);
  return new Promise((resolve) => {
    dialogBoxInstance.callback = resolve;
  });
};
Vue.component("go-dialog", godialog);
Vue.component("breadcrumb", Breadcrumb);
Vue.component("DragTable", DragTable);
Vue.component("BPagination", BPagination);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$goDialog = dialogBoxCtl;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$checkPermission = checkPermission;

let timer = null;

Vue.directive("permission", permission);

Vue.prototype.debounce = (func, time) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(func, time);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

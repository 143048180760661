import request from "./request";
import declare from "@/router/declare";

const login = async (username, password) => {
  return request.get("/login", { params: { username, password } });
};

const logout = async () => {
  return request.get("/logout");
};

const getUser = () => {
  return Promise.resolve(declare);
};
const auth = {
  login,
  logout,
  getUser,
};

export default auth;

export default [
  {
    path: "/clear",
    name: "clear",
    meta: {
      label: "清关",
      sort: 3,
    },
    component: () => import("@/views/clear/index"),
    children: [
      {
        path: "/clear/wait",
        name: "clearWait",
        meta: {
          label: "待清关",
        },
        component: () => import(`@/views/clear/wait/index`),
      },
      {
        path: "/clear/successWait",
        name: "clearSuccessWait",
        meta: {
          label: "清关成功待复检",
        },
        component: () => import(`@/views/clear/successWait/index`),
      },
      {
        path: "/clear/failure",
        name: "clearFailure",
        meta: {
          label: "清关失败",
        },
        component: () => import(`@/views/clear/failure/index`),
      },
      {
        path: "/clear/failureDetail",
        name: "claerFailureDetail",
        meta: {
          label: "清关失败-编辑",
          hide: true,
        },
        component: () => import(`@/views/clear/detail/index`),
      },
      {
        path: "/clear/transport",
        name: "clearTransport",
        meta: {
          label: "国内运输中",
        },
        component: () => import(`@/views/clear/transport/index`),
      },
    ],
  },
];

import request from "./request";

/***************打单*****************/

// 获取寄件人信息
const getSenderList = async (params) => {
  return request.get("/receipt/sender/list", {
    params: { ...params },
  });
};

// 新增寄件人
const addSender = async (data) => {
  return request.post("/receipt/sender/create", data);
};

// 寄件人编辑
const updateSender = async (data) => {
  return request.put("/receipt/sender/edit", data);
};

// 寄件人删除
const removeSender = async (id) => {
  return request.delete("/receipt/sender/delete", {
    data: { id },
  });
};

// 寄件人详情
const getSenderDetail = async (id) => {
  return request.get("/receipt/sender/detail", {
    params: { id },
  });
};

// 通过面单ID查询寄件人详情
const getSenderDetailBySheet = async (id) => {
  return request.get("/receipt/sender", {
    params: { id },
  });
};

// 面单查询
const getsheetList = async (params) => {
  return request.get("/receipt/list", {
    params: { ...params },
  });
};

// 面单新增
const addSheet = async (data) => {
  return request.post("/receipt/create", data);
};

// 面单编辑
const updateSheet = async (data) => {
  return request.put("/receipt/edit", data);
};

// 面单删除
const removeSheet = async (id) => {
  return request.delete("/receipt/delete", {
    data: { id },
  });
};

// 面单详情
const getSheetDetail = async (id) => {
  return request.get("/receipt/detail", {
    params: { id },
  });
};

// 面单模板下拉框列表，绑定面单时使用
const getSheetSelect = async () => {
  return request.get("/receipt/select");
};

// 面单模板下拉框列表，绑定面单时使用
const getSheetTemplateSelect = async () => {
  return request.get("/receipt/template/select");
};

// 面单模板列表
const getSheetTemplateList = async () => {
  return request.get("/receipt/template/list");
};

// 面单模板新增
const addSheetTemapte = async (data) => {
  return request.post("/receipt/template/create", data);
};

// 面单模板详情
const getSheetTemapteDetail = async (id) => {
  return request.get("/receipt/template/detail", {
    params: { id },
  });
};

// 面单模板编辑
const updateSheetTemapte = async (data) => {
  return request.put("/receipt/template/edit", data);
};

// 面单模板删除
const removeSheetTemapte = async (id) => {
  return request.delete("/receipt/template/delete", {
    data: { id },
  });
};

// 包裹信息
const getPackageInfo = async (authentication, id) => {
  return request.get("/receipt/info", {
    params: { authentication, id },
  });
};

// 面单基础导入
const expressImport = async (data) => {
  return request.post("/mtk/import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 面单基础数据列表
const expressList = async (data) => {
  return request.get("/mtk/list", { params: data });
};

// 删除面单基础数据
const removeExpress = async (id) => {
  return request.delete("/mtk/delete", {
    data: { id },
  });
};

// 编辑面单基础数据
const updateExpress = async (data) => {
  return request.post("/mtk/update", data);
};

// 编辑面单基础数据详情
const expressDetail = async (platform_code) => {
  return request.get("/mtk/detail", {
    params: { platform_code },
  });
};

// 添加打印次数
const addPrintNum = async (platform_code) => {
  return request.post("/mtk/print/add", {
    platform_code,
  });
};

const expressBill = {
  expressImport,
  expressList,
  removeExpress,
  getSenderList,
  updateExpress,
  expressDetail,
  addSender,
  updateSender,
  removeSender,
  getsheetList,
  addSheet,
  updateSheet,
  removeSheet,
  getSenderDetail,
  getSheetDetail,
  getSheetTemplateList,
  getPackageInfo,
  getSheetTemplateSelect,
  getSenderDetailBySheet,
  addSheetTemapte,
  getSheetSelect,
  updateSheetTemapte,
  removeSheetTemapte,
  getSheetTemapteDetail,
  addPrintNum,
};

export default expressBill;

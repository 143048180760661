import request from "./request";

/***************标准物流待打板*****************/

// 获取打板导入模板
const getDabanTemplate = () => {
  return request.get("/plate/parcel/export", {
    responseType: "blob",
  });
};
// 导入打板包裹数据
const importDabanPackage = (data) => {
  return request.post("/plate/parcel/create", data);
};

// 获取打板包裹列表
const getDabanPackageList = (params) => {
  return request.get("/plate/parcel/list", {
    params: {
      ...{ is_plate: 1, clearance_status: 0 },
      ...params,
    },
  });
};

// 移除打板包裹
const removeDabanPackage = (id) => {
  return request.delete("/plate/parcel/delete", { data: { id } });
};

// 数据刷新
const refreshData = () => {
  return request.post("/plate/parcel/syncParcelDataList");
};

// 同步包裹信息
const syncWaitParcelDataList = () => {
  return request.post("/plate/parcel/syncWaitParcelDataList");
};

// 批量删除
const batchDelete = (ids) => {
  return request.post("/plate/parcel/multiParcelDelete", { ids });
};

/***************卡板管理*****************/

// 获取打板列表
const getDabanList = (params) => {
  return request.get("/plate/list", { params });
};

// 新增打板
const addDaban = (data) => {
  return request.post("/plate/create", data);
};

// 根据物流id查询口岸列表
const getPortListByLogistics = (id) => {
  return request.get("/plate/port", { params: { logistics_id: id } });
};

// 编辑打板
const editDaban = (data) => {
  return request.put("/plate/edit", data);
};

// 转换为未完成打板
const convertToWait = (id) => {
  return request.put("/plate/status", { id });
};

// 删除打板
const removeDaban = (id) => {
  return request.delete("/plate/delete", { data: { id } });
};

// 添加包裹到打板
const addPackageToDaban = (data) => {
  return request.post("/plate/add", data);
};

// 上一个已添加的包裹
const getLastAddedPackage = (id) => {
  return request.get("/plate/ship", { params: { id } });
};

// 移除打板内的某个包裹
const removePackageFromDaban = (data) => {
  return request.delete("/plate/remove", { data: data });
};

// 获取打板内包裹列表
const getPackageListFromDaban = (id, pagination) => {
  return request.get("/plate/show", { params: { id, ...pagination } });
};

// 打板详情
const getDabanDetail = (id) => {
  return request.get("/plate/detail", { params: { id } });
};

// 下载某个打板中包裹数据
const exportDabanPackage = (id) => {
  return request.get("/plate/export", { params: { id }, responseType: "blob" });
};

// 获取品牌列表
const getBrandList = () => {
  return request.get("/plate/brand");
};

const ship = {
  getDabanPackageList,
  removeDabanPackage,
  importDabanPackage,
  getDabanTemplate,
  getDabanList,
  addDaban,
  editDaban,
  removeDaban,
  addPackageToDaban,
  removePackageFromDaban,
  getDabanDetail,
  exportDabanPackage,
  getPortListByLogistics,
  convertToWait,
  getLastAddedPackage,
  getPackageListFromDaban,
  refreshData,
  syncWaitParcelDataList,
  getBrandList,
  batchDelete,
};

export default ship;

import axios from "./request";

// 获取包裹列表
const getParcelList = async (params) => {
  return axios.get("/declare/parcel/list", {
    params: { ...params },
  });
};

// 获取口岸列表
const getPortList = async () => {
  return axios.get("/declare/parcel/port");
};

// 导入包裹
const importParcel = async (data) => {
  return axios.post("/declare/parcel/create", data);
};

// 包裹数据模板
const exportParcelTemplate = async () => {
  return axios.get("/export", {
    responseType: "blob",
  });
};

// 获取包裹详情
const getParcelDetail = async (ship_id, is_base) => {
  return axios.get("/declare/parcel/detail", { params: { ship_id, is_base } });
};

// 编辑包裹
const updateParcel = async (data) => {
  return axios.put("/declare/parcel/edit", data);
};

// 获取收件人信息
const getUserSecretInfo = async (id) => {
  return axios.get("/declare/parcel/recipient", { params: { id } });
};

// 编辑收件人信息
const updateUserSecretInfo = async (data) => {
  return axios.put("/declare/parcel/recipient/edit", data);
};

// 获取省市区
const getCity = async (id = 5000) => {
  return axios.get("/city", { params: { id } });
};

// 变更锁单状态，true:是，false:否
const updateLockStatus = async (id, status) => {
  const isLock = status ? "2" : "1";
  return axios.put("/declare/parcel/status/edit", { id: id, status: isLock });
};

// 计算税金
const getTax = async (params) => {
  return axios.get("/declare/parcel/tax", { params: params });
};

// 查询物流列表
const getLogisticsList = async () => {
  return axios.get("/declare/parcel/logistics");
};

// 更新口岸
const updatePort = async (data) => {
  return axios.put("/declare/parcel/port/edit", data);
};

// 包裹列表导出
const exportParcel = async (params) => {
  return axios.get("/declare/parcel/export", {
    params: params,
    responseType: "blob",
  });
};

// 查询出税率
const getTaxRate = async () => {
  return axios.get("/declare/parcel/tax/rate");
};

// 一键推单
const pushLockParcel = async () => {
  return axios.get("/declare/parcel/push");
};

// 出税列表
const getTaxList = async (params) => {
  return axios.get("/declare/parcel/tax/list", { params });
};

// 出税包裹出税率
const getTaxInfo = async (params) => {
  return axios.get("/declare/parcel/tax/status", { params });
};

// 出税包裹导出
const exportTaxListParcel = async (params) => {
  return axios.get("/declare/parcel/tax/export", {
    params: params,
    responseType: "blob",
  });
};

// 撤销包裹
const revokePackage = async (ship_id) => {
  return axios.delete("/declare/parcel/delete", {
    data: { ship_id },
  });
};

/********************************常量接口***************************************/

// 查询某个口岸的常量列表
const getConstantList = async (id, paginations = {}) => {
  return axios.get("/constant/list", {
    params: { ...{ port: id }, ...paginations },
  });
};

// 新建常量
const createConstant = async (data) => {
  return axios.post("/constant/create", data);
};

// 查询常量详情
const getConstantDetail = async (id) => {
  return axios.get("/constant/detail", { params: { id: id } });
};

// 编辑常量
const editConstant = async (data) => {
  return axios.put("/constant/edit", data);
};

// 常量字段
const getConstantField = async (id) => {
  return axios.get("/constant/field", { params: { port: id } });
};

// 删除常量
const removeConstant = async (id) => {
  return axios.delete("/constant/delete", {
    data: { id },
  });
};

/******************************业务代码*****************************************/
// 获取业务代码列表
const getBusinessList = async () => {
  return axios.get("/business/list");
};

// 获取业务代码详情
const getBusinessDetail = async (id) => {
  return axios.get("/business/detail", { params: { id } });
};

/******************************邮政系列表*****************************************/
// 获取邮政系包裹列表
const getEmsParcelList = async (params) => {
  return axios.get("/ems/list", {
    params: { ...params },
  });
};

// 获取邮政系包裹详情
const getEmsParcelDetail = async (id) => {
  return axios.get("/ems/detail", { params: { id } });
};

// 邮政系包裹编辑
const editEmsParcel = async (data) => {
  return axios.put("/ems/edit", data);
};

// 邮政系包裹导出
const exportEmsParcel = async (
  logistics_id,
  export_type,
  ids_str = undefined
) => {
  return axios.get("/ems/export", {
    params: {
      logistics_id,
      export_type,
      list_type: ids_str ? "exported" : undefined,
      ids_str,
    },
    responseType: "blob",
  });
};

// 邮政系包裹刷新
const flushEmsParcel = async (logistics_id) => {
  return axios.get("/ems/syncShip");
};

// 邮政系包裹收件人详情
const getEmsRecipientDetail = async (id) => {
  return axios.get("/ems/recipient", { params: { id } });
};

// 状态变更
const updateEmsParcelStatus = async (data) => {
  return axios.put("/ems/status", data);
};

// 状态变更
const updateEmsRecipient = async (data) => {
  return axios.put("/ems/recipient/edit", data);
};

/******************************代理清关小包*****************************************/
// 获取代理清关小包列表
const getProxyDeclareList = async (params) => {
  return axios.get("/customs/list", {
    params: { ...params },
  });
};

// 获取代理清关小包详情
const getProxyDeclareDetail = async (id) => {
  return axios.get("/customs/detail", { params: { id } });
};

// 代理清关小包编辑
const editProxyDeclare = async (data) => {
  return axios.put("/customs/edit", data);
};

/**
 * 代理清关小包收件人详情
 * @param {number|string} id 收件人详情id
 * @returns {any}
 */
const getProxyDeclareRecipientDetail = async (id) => {
  return axios.get("/customs/recipient", { params: { id } });
};

// 代理清关小包收件人编辑
const editProxyDeclareRecipient = async (data) => {
  return axios.put("/customs/recipient/edit", data);
};

// 代理清关小包导出
const exportProxyDeclare = async (port) => {
  return axios.get("/customs/export", {
    params: { port },
    responseType: "blob",
  });
};

// 代理清关小包锁单
// is_lock 是否锁单(1:否 2:是)
const lockProxyDeclare = async (id, is_lock) => {
  return axios.put("/customs/lock", { id, is_lock });
};

// 代理清关小包已导出数据列表
const getProxyDeclareExportList = async (params) => {
  return axios.get("/customs/batch/list", { params: params });
};

// 撤销包裹
const revokeProxyDeclarePackage = async (id) => {
  return axios.delete("/customs/delete", {
    data: { ship_id: id },
  });
};

const declare = {
  getUserSecretInfo,
  updateUserSecretInfo,
  getParcelList,
  getPortList,
  importParcel,
  updateLockStatus,
  getParcelDetail,
  exportParcelTemplate,
  getConstantList,
  createConstant,
  getConstantDetail,
  editConstant,
  getBusinessList,
  getBusinessDetail,
  getConstantField,
  removeConstant,
  getCity,
  getTax,
  getLogisticsList,
  updatePort,
  exportParcel,
  updateParcel,
  getTaxRate,
  pushLockParcel,
  getEmsParcelList,
  getEmsParcelDetail,
  editEmsParcel,
  exportEmsParcel,
  getEmsRecipientDetail,
  updateEmsParcelStatus,
  updateEmsRecipient,
  getTaxList,
  getTaxInfo,
  exportTaxListParcel,
  getProxyDeclareList,
  getProxyDeclareDetail,
  editProxyDeclare,
  getProxyDeclareRecipientDetail,
  editProxyDeclareRecipient,
  exportProxyDeclare,
  lockProxyDeclare,
  getProxyDeclareExportList,
  revokePackage,
  revokeProxyDeclarePackage,
  flushEmsParcel,
};

export default declare;

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { auth, config } from "@/api";

export default {
  async mounted() {
    const data = await config.getUser(this.$store.state.CMS.userInfo.id);
    data.forEach((router) => {
      this.$router.addRoute("Home", router);
    });
    const routers = this.$router.getRoutes();
    this.$store.commit("updateRouters", routers);
    this.$store.commit("updateOriginRouter", data);
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  width: 100vw;
  height: 100vh;
  // background-color: #fff;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

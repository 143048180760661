<!-- 登录页 -->
<template>
  <div class="container">
    <div class="wrapper center">
      <el-form
        class="form"
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="79px"
        label-position="top"
        hide-required-asterisk
      >
        <h1 class="title">Welcome Back!</h1>

        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="formData.username"
            placeholder="请输入用户名"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="formData.password"
            placeholder="请输入密码"
            clearable
            show-password
            :style="{ width: '100%' }"
            @keyup.enter.native="submit"
          ></el-input>
        </el-form-item>
        <el-form-item size="large">
          <el-button type="primary" @click="submit" :style="{ width: '100%' }"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { auth, config } from "../api";

export default {
  components: {},
  data() {
    return {
      formData: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    async submit() {
      this.$refs["elForm"].validate(async (valid) => {
        if (!valid) return;

        const res = await auth.login(
          this.formData.username,
          this.formData.password
        );
        if (res.code === 1000) {
          this.$store.commit("login", res.data[0]);
          await this.initRouter();
          this.$router.replace("/");
        }
      });
    },
    async initRouter() {
      const data = await config.getUser(this.$store.state.CMS.userInfo.id);
      data.forEach((router) => {
        this.$router.addRoute("Home", router);
      });
      const routers = this.$router.getRoutes();
      this.$store.commit("updateRouters", routers);
      this.$store.commit("updateOriginRouter", data);
    },
  },
};
</script>
<style scoped>
.form {
  text-align: initial;
}

.container {
  background: url("../assets/images/loginBg.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.wrapper {
  background: url("../assets/images/loginBg2.png") no-repeat;
  background-size: contain;
  height: 646px;
  width: 998px;
}
.form {
  position: absolute;
  top: 270px;
  transform: translate(50%, -50%);
  right: 270px;
  width: 400px;
}
::v-deep.el-form--label-top .el-form-item__label {
  font-weight: bold;
  padding: 0px;
}
::v-deep.el-form-item {
  margin-bottom: 15px;
}
.title {
  padding-bottom: 15px;
}
</style>

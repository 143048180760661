import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./router";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "login") {
    next();
  } else {
    const userInfo = store.state.CMS.userInfo;
    if (userInfo && userInfo.token) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

export default router;
